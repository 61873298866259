import React, {useState, useEffect} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {itemAllCourseState, userState, RoadmapDetails} from "../../atom";
import {Link, useParams} from 'react-router-dom';
import i18next from "i18next";
import coursesData from "../../data/coursesData.json";
import {Clock, Users, Target, ChevronDown, BookOpen} from 'lucide-react';

// Import components
import Button from '../../components/Button/Button';
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import CourseDes from "../../components/CourseDes/CourseDes";
import QuestionModal from "../../components/PreviousYearQuestionModal/QuestionModal";
import Expander from "../../components/Expander/Expander";
import LoginModel from "../../components/LoginModel/LoginModel";

// Import API functions
import {apiGetCourse, apigetMessartDetails} from "../../services/api/generalApi";
import {apiGetCourseLessons} from "../../services/api/courseDetails";

// Import course images
import ai from '../../assets/images/NewMassarsPics/ai.svg';
import coding from '../../assets/images/NewMassarsPics/coding.svg';
import electronic from '../../assets/images/NewMassarsPics/electronicAndDesign.svg';
import gameDevelopment from '../../assets/images/NewMassarsPics/gameDevelopment.svg';
import graphicDesign from '../../assets/images/NewMassarsPics/GraphicDesign.svg';
import iqTests from '../../assets/images/NewMassarsPics/iqTests.svg';
import schoolsContest from '../../assets/images/NewMassarsPics/schoolsContest.svg';
import webPath from '../../assets/images/NewMassarsPics/webPath.svg';
import mayPath from '../../assets/images/massarMay.svg';

const CourseDetailsSkeleton = () => {
    const shimmerStyle = {
        backgroundImage: 'linear-gradient(90deg, #f3f4f6 0%, #e5e7eb 50%, #f3f4f6 100%)',
        backgroundSize: '200% 100%',
        animation: 'shimmer 2s linear infinite',
    };

    return (
        <div className="min-h-screen bg-gray-50 py-8">
            <style>
                {`
          @keyframes shimmer {
            0% { background-position: -200% 0; }
            100% { background-position: 200% 0; }
          }
        `}
            </style>
            <div className="container mx-auto px-4">
                <div className="bg-white rounded-3xl overflow-hidden mb-8">
                    <div className="grid md:grid-cols-12 gap-6 p-6">
                        {/* Left Column - Image and Stats */}
                        <div className="hidden md:flex md:flex-col md:col-span-4 space-y-6">
                            {/* Image Skeleton */}
                            <div className="w-64 h-64 rounded-2xl mx-auto" style={shimmerStyle}/>

                            {/* Stats Skeletons */}
                            {[1, 2, 3].map((_, index) => (
                                <div key={index} className="bg-gray-50 rounded-xl p-4">
                                    <div className="w-10 h-10 rounded-full mx-auto mb-2" style={shimmerStyle}/>
                                    <div className="h-4 rounded w-3/4 mx-auto mb-2" style={shimmerStyle}/>
                                    <div className="h-4 rounded w-1/2 mx-auto" style={shimmerStyle}/>
                                </div>
                            ))}
                        </div>

                        {/* Right Column - Content */}
                        <div className="hidden md:block md:col-span-8">
                            <div className="h-8 rounded-lg w-3/4 mb-4" style={shimmerStyle}/>

                            <div className="space-y-2 mb-6">
                                <div className="h-4 rounded w-full" style={shimmerStyle}/>
                                <div className="h-4 rounded w-5/6" style={shimmerStyle}/>
                                <div className="h-4 rounded w-4/6" style={shimmerStyle}/>
                            </div>

                            <div className="grid md:grid-cols-2 gap-8 mb-6">
                                {[1, 2].map((_, index) => (
                                    <div key={index} className="bg-gray-50 rounded-xl p-4">
                                        <div className="h-6 rounded w-1/2 mb-4" style={shimmerStyle}/>
                                        <div className="space-y-2">
                                            {[1, 2, 3].map((_, i) => (
                                                <div key={i} className="flex items-center gap-2">
                                                    <div className="w-4 h-4 rounded-full shrink-0"
                                                         style={shimmerStyle}/>
                                                    <div className="h-4 rounded w-full" style={shimmerStyle}/>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="h-12 rounded-xl w-full" style={shimmerStyle}/>
                        </div>

                        {/* Mobile Layout */}
                        <div className="md:hidden col-span-12 space-y-6">
                            <div className="w-64 h-64 rounded-2xl mx-auto" style={shimmerStyle}/>

                            <div className="grid grid-cols-1 gap-4">
                                {[1, 2, 3].map((_, index) => (
                                    <div key={index} className="bg-gray-50 rounded-xl p-4">
                                        <div className="w-8 h-8 rounded-full mx-auto mb-2" style={shimmerStyle}/>
                                        <div className="h-4 rounded w-3/4 mx-auto mb-2" style={shimmerStyle}/>
                                        <div className="h-4 rounded w-1/2 mx-auto" style={shimmerStyle}/>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Course Content Section Skeleton */}
                <div className="bg-white rounded-3xl p-6">
                    <div className="flex items-center gap-2 mb-6">
                        <div className="w-10 h-10 rounded-lg" style={shimmerStyle}/>
                        <div className="h-6 rounded w-48" style={shimmerStyle}/>
                    </div>

                    <div className="space-y-4">
                        {[1, 2, 3].map((_, index) => (
                            <div key={index} className="border border-gray-100 rounded-xl p-4">
                                <div className="flex items-center justify-between">
                                    <div className="h-5 rounded w-1/3" style={shimmerStyle}/>
                                    <div className="w-8 h-8 rounded-full" style={shimmerStyle}/>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const getImageByRoadmapId = (id) => {
    switch (parseInt(id)) {
        case 1:
            return webPath;
        case 14:
            return mayPath;
        case 2:
            return electronic;
        case 8:
            return gameDevelopment;
        case 3:
            return iqTests;
        case 12:
            return graphicDesign;
        case 11:
            return coding;
        case 7:
            return schoolsContest;
        case 13:
            return ai;
        default:
            return ai;
    }
};

const CourseDetails = () => {
    const {id: courseId} = useParams();
    const userId = localStorage.getItem("id");
    const [data2, setData2] = useRecoilState(itemAllCourseState);
    const [messaratPackage, setMessaratPackage] = useRecoilState(RoadmapDetails);
    const [loading, setLoading] = useState(true);
    const [courseData, setCourseData] = useState(null);
    const [filteredCourses, setFilteredCourses] = useState([]);
    const user = useRecoilValue(userState);
    const langUpperCase = i18next.language.toUpperCase();

    useEffect(() => {
        const matchingCourse = coursesData.courses.find(
            course => course.id === parseInt(courseId)
        );
        setCourseData(matchingCourse);
    }, [courseId]);

    const getCourseDetails = async () => {
        setLoading(true);
        try {
            const courseData = await apiGetCourse(langUpperCase, userId);
            setData2(courseData);

            const messaratData = await apigetMessartDetails(userId);

            const filteredData = messaratData.find(item => item.id == courseId);

            if (!filteredData) {
                setLoading(false);
                return;
            }

            setMessaratPackage(filteredData);

            const updatedMessaratCourse = await Promise.all(
                filteredData.messaratCourse.map(async (course) => {
                    try {
                        const lessonsData = await apiGetCourseLessons(course.courseId);
                        return {
                            ...course,
                            lessons: lessonsData
                        };
                    } catch (error) {
                        return {
                            ...course,
                            lessons: []
                        };
                    }
                })
            );

            console.log("Updated Messarat Course:", updatedMessaratCourse);

            setMessaratPackage(prevState => ({
                ...prevState,
                messaratCourse: updatedMessaratCourse
            }));

            const filtered = updatedMessaratCourse.filter(
                (el) => el.messaratDetailsDto.isMessar !== true
            );
            setFilteredCourses(filtered);

        } catch (error) {
            console.error("Error in getCourseDetails:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getCourseDetails();
    }, [courseId]);

    const scrollToContent = () => {
        document.getElementById('course-content').scrollIntoView({
            behavior: 'smooth'
        });
    };

    const totalLessons = filteredCourses?.reduce((acc, course) =>
        acc + (course.lessons?.length || 0), 0) || 0;

    const estimatedDuration = "" +
        "1 أشهر";

    if (loading || !courseData) {
        return <CourseDetailsSkeleton/>;
    }

    return (
        <div className="min-h-screen bg-gray-50 py-8">
            <div className="container mx-auto px-4">
                {/* Enhanced Header Section */}
                <div className="bg-white rounded-3xl overflow-hidden mb-8">
                    <div className="grid md:grid-cols-12 gap-6 p-6">
                        {/* Mobile Layout (will be hidden on md and up) */}
                        <div className="md:hidden col-span-12 space-y-6">
                            {/* Image */}
                            <div className="flex justify-center">
                                <div className="w-64">
                                    <img
                                        src={getImageByRoadmapId(courseData.id)}
                                        alt={courseData.title}
                                        className="w-full h-auto rounded-2xl"
                                    />
                                </div>
                            </div>

                            {/* Info */}
                            <div className="space-y-6">
                                <h1 className="text-2xl font-bold text-custom-blue">
                                    {courseData.title}
                                </h1>

                                <p className="text-gray-600 leading-relaxed text-justify" style={{direction: 'rtl'}}>
                                    {courseData.description}
                                </p>

                                {/* Course Stats */}
                                <div className="grid grid-cols-1 gap-4">
                                    <div className="bg-custom-blue-50 rounded-xl p-4 text-center">
                                        <Users className="w-6 h-6 text-custom-blue mx-auto mb-2"/>
                                        <span className="text-sm text-gray-600 block">الفئة المستهدفة</span>
                                        <span className="font-semibold block text-sm">الطلاب الذين تتراوح أعمارهم بين 10-17 سنة</span>
                                    </div>
                                    <div className="bg-custom-blue-50 rounded-xl p-4 text-center">
                                        <Target className="w-6 h-6 text-custom-blue mx-auto mb-2"/>
                                        <span className="text-sm text-gray-600 block">عدد الدروس</span>
                                        <span className="font-semibold block">32 درس</span>
                                    </div>
                                    <div className="bg-custom-blue-50 rounded-xl p-4 text-center">
                                        <Clock className="w-6 h-6 text-custom-blue mx-auto mb-2"/>
                                        <span className="text-sm text-gray-600 block">المدة المتوقعة</span>
                                        <span className="font-semibold block">1-2 أشهر</span>
                                    </div>
                                </div>

                                {/* Action Button */}
                                {messaratPackage.isEnrolled ? (
                                    <button
                                        onClick={scrollToContent}
                                        className="w-full bg-custom-blue hover:bg-blue-600 text-white font-semibold px-8 py-4 rounded-xl transition-colors flex items-center justify-center gap-2"
                                    >
                                        ابدأ التعلم الآن
                                        <ChevronDown className="w-5 h-5"/>
                                    </button>
                                ) : (
                                    <div className="space-y-4">
                                        <div className="text-2xl font-bold text-custom-blue text-center">
                                            {courseData.price.toLocaleString()} د.ع
                                        </div>
                                        {userId ? (
                                            <Link
                                                to={`/pay/${courseData.id}`}
                                                className="w-full bg-custom-yellow hover:bg-yellow-400 text-zinc-900 font-semibold px-8 py-4 rounded-xl transition-colors text-center block"
                                            >
                                                اشترك بالمسار
                                            </Link>
                                        ) : (
                                            <button
                                                className="w-full bg-custom-yellow hover:bg-yellow-400 text-zinc-900 font-semibold px-8 py-4 rounded-xl transition-colors"
                                                data-bs-toggle="modal"
                                                data-bs-target="#loginModal">
                                                اشترك بالمسار
                                            </button>
                                        )}
                                    </div>
                                )}

                                {/* Course Goals and Learning Outcomes */}
                                <div className="grid grid-cols-1 gap-6">
                                    <div className="bg-custom-blue-50 rounded-xl p-4">
                                        <h3 className="text-xl font-bold mb-4 text-custom-blue flex items-center gap-2">
                                            <Target className="w-6 h-6 text-custom-blue"/>
                                            أهداف المسار
                                        </h3>
                                        <ul className="space-y-3">
                                            {courseData.objectives.map((objective, index) => (
                                                <li key={index} className="flex items-start text-right">
                                                    <span
                                                        className="text-custom-blue ml-2 font-bold">{index + 1}.</span>
                                                    <span className="text-gray-600">{objective}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    <div className="bg-custom-blue-50 rounded-xl p-4">
                                        <h3 className="text-xl font-bold mb-4 text-custom-blue flex items-center gap-2">
                                            <Target className="w-6 h-6 text-custom-blue"/>
                                            ماذا ستتعلم
                                        </h3>
                                        <ul className="space-y-3">
                                            {courseData.learningOutcomes.map((outcome, index) => (
                                                <li key={index} className="flex items-start text-right">
                                                    <span
                                                        className="text-custom-blue ml-2 font-bold">{index + 1}.</span>
                                                    <span className="text-gray-600">{outcome}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Desktop Layout (hidden on mobile) */}
                        <div className="hidden md:flex md:flex-col md:col-span-4 items-center space-y-6">
                            <div className="relative w-64">
                                <img
                                    src={getImageByRoadmapId(courseData.id)}
                                    alt={courseData.title}
                                    className="w-full h-auto rounded-2xl"
                                />
                            </div>

                            {/* Course Stats Under Image */}
                            <div className="w-full space-y-4">
                                <div className="bg-custom-blue-50 rounded-xl p-4 text-center">
                                    <Users className="w-6 h-6 text-custom-blue mx-auto mb-2"/>
                                    <span className="text-sm text-gray-600 block">الفئة المستهدفة</span>
                                    <span className="font-semibold block text-sm">{courseData.targetAudience}</span>
                                </div>
                                <div className="bg-custom-blue-50 rounded-xl p-4 text-center">
                                    <Target className="w-6 h-6 text-custom-blue mx-auto mb-2"/>
                                    <span className="text-sm text-gray-600 block">عدد الدروس</span>
                                    <span className="font-semibold block">{totalLessons} درس</span>
                                </div>
                                <div className="bg-custom-blue-50 rounded-xl p-4 text-center">
                                    <Clock className="w-6 h-6 text-custom-blue mx-auto mb-2"/>
                                    <span className="text-sm text-gray-600 block">المدة المتوقعة</span>
                                    <span className="font-semibold block">{estimatedDuration}</span>
                                </div>
                            </div>
                        </div>

                        {/* Desktop Content (hidden on mobile) */}
                        <div className="hidden md:block md:col-span-8">
                            <h1 className="text-3xl font-bold mb-4 text-custom-blue">
                                {courseData.title}
                            </h1>

                            <p className="text-gray-600 mb-6 leading-relaxed text-justify" style={{direction: 'rtl'}}>
                                {courseData.description}
                            </p>

                            {/* Course Goals and Learning Outcomes */}
                            <div className="grid md:grid-cols-2 gap-8 mb-6">
                                {/* Objectives */}
                                <div className="bg-custom-blue-50 rounded-xl p-4">
                                    <h3 className="text-xl font-bold mb-4 text-custom-blue flex items-center gap-2">
                                        <Target className="w-6 h-6 text-custom-blue"/>
                                        أهداف المسار
                                    </h3>
                                    <ul className="space-y-3">
                                        {courseData.objectives.map((objective, index) => (
                                            <li key={index} className="flex items-start text-right">
                                                <span className="text-custom-blue ml-2 font-bold">{index + 1}.</span>
                                                <span className="text-gray-600">{objective}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                {/* Learning Outcomes */}
                                <div className="bg-custom-blue-50 rounded-xl p-4">
                                    <h3 className="text-xl font-bold mb-4 text-custom-blue flex items-center gap-2">
                                        <Target className="w-6 h-6 text-custom-blue"/>
                                        ماذا ستتعلم
                                    </h3>
                                    <ul className="space-y-3">
                                        {courseData.learningOutcomes.map((outcome, index) => (
                                            <li key={index} className="flex items-start text-right">
                                                <span className="text-custom-blue ml-2 font-bold">{index + 1}.</span>
                                                <span className="text-gray-600">{outcome}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            {/* Action Button */}
                            {messaratPackage.isEnrolled ? (
                                <button
                                    onClick={scrollToContent}
                                    className="w-full bg-custom-blue hover:bg-blue-600 text-white font-semibold px-8 py-4 rounded-xl transition-colors flex items-center justify-center gap-2"
                                >
                                    ابدأ التعلم الآن
                                    <ChevronDown className="w-5 h-5"/>
                                </button>
                            ) : (
                                <div className="flex items-center flex-col gap-4">
                                    <div className="text-2xl font-bold text-custom-blue">
                                        {courseData.price.toLocaleString()} د.ع
                                    </div>
                                    {userId ? (
                                        <Link
                                            to={`/pay/${courseData.id}`}
                                            className="flex-1 w-full bg-custom-yellow hover:bg-yellow-400 text-zinc-900 font-semibold px-8 py-4 rounded-xl transition-colors text-center"
                                        >
                                            اشترك بالمسار
                                        </Link>
                                    ) : (
                                        <button
                                            className="flex-1 w-full bg-custom-yellow hover:bg-yellow-400 text-zinc-900 font-semibold px-8 py-4 rounded-xl transition-colors"
                                            data-bs-toggle="modal"
                                            data-bs-target="#loginModal"
                                        >
                                            اشترك بالمسار
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Course Content Section */}
                <div id="course-content" className="bg-white rounded-3xl p-6 scroll-mt-8">
                    <h2 className="text-2xl font-bold mb-6 text-custom-blue flex items-center gap-2">
                        <div className="bg-custom-blue-50 p-2 rounded-lg">
                            <BookOpen className="w-6 h-6 text-custom-blue"/>
                        </div>
                        محتويات المسار
                    </h2>
                    <div className="space-y-4">
                        {filteredCourses?.map((course, index) => (
                            <Expander
                                key={index}
                                title={course.messaratDetailsDto.title}
                                lessons={course.lessons || []}
                                detailsid={course.courseId}
                                isFirstCourse={index === 0}
                                isEnrolled={messaratPackage.isEnrolled}
                            />
                        ))}
                    </div>
                </div>
            </div>

            <LoginModel/>
        </div>
    );
};

export default CourseDetails;