import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import contestsData from '../../assets/json/contests.json';
import './CompetitionsPage.scss';

const CompetitionsPage = () => {
    const [contests, setContests] = useState([]);

    useEffect(() => {
        const loadContests = async () => {
            const loadedContests = await Promise.all(
                contestsData.contests.map(async (contest) => {
                    try {
                        const imageModule = await import(`../../assets${contest.image}.svg`);
                        return { ...contest, image: imageModule.default };
                    } catch (error) {
                        console.error(`Failed to load image for ${contest.title}:`, error);
                        return contest;
                    }
                })
            );
            setContests(loadedContests);
        };

        loadContests();
    }, []);

    return (
        <div className="competitions bg bg-comp">
            <div className="container my-4">
                <h1 className="text-custom-blue text-3xl text-center font-semibold tracking-wide py-2">المسابقات البرمجية</h1>
                <p className="text-xl text-center pb-10">تعرف على مسابقاتنا المقامة سنويا ،وشارك عند فتح التسجيل!</p>
                <div className="flex flex-wrap items-center space-y-16">
                    {contests.map((contest) => (
                        <div key={contest.id} className="w-full flex items-center justify-center">
                            <div className="flex items-center justify-center w-full my-4">
                                {/*<img*/}
                                {/*    className="w-52 ml-20"*/}
                                {/*    src={new URL(contest.image, import.meta.url).href}*/}
                                {/*    alt={contest.title}*/}
                                {/*/>*/}
                                <div className="w-4/12">
                                    <h3 className="text-xl">{contest.title}</h3>
                                    <p className="text-sm py-0 text-zinc-800">{contest.description}</p>
                                    <div className="flex justify-end mt-4">
                                        {contest.registrationLink && (
                                            <div className="ml-3">
                                                {/*<Link*/}
                                                {/*    to={contest.registrationLink}*/}
                                                {/*    className="rounded-full font-semibold tracking-wide bg-custom-yellow text-zinc-900 px-10 py-3">*/}
                                                {/*    سجل الان*/}
                                                {/*</Link>*/}
                                            </div>
                                        )}
                                        <div>
                                            <Link
                                                to={`/competition/${contest.id}`}
                                                className="rounded-full font-semibold tracking-wide bg-custom-blue px-10 py-3">
                                                رؤية التفاصيل
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CompetitionsPage;